<template>
  <section>
    <b-card>
      <b-card-header style="padding-top: 0px; padding-right: 0px">
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4>
              <b style="color: cornflowerblue">Daftar Hasil TryOut</b>
            </h4>
          </b-card-title>
        </div>
      </b-card-header>

      <b-col cols="12">
        <b-form method="post" target="_blank" enctype="multipart/form-data" @submit="list_report_event">
        <b-row>
          <b-col md="6" xl="3" class="mb-1">
          </b-col>
        <b-col md="6" xl="4" class="mb-1">
          <label for="textarea-default"><b>Event Tryout</b></label>
              <v-select
                name="register-kampus"
                placeholder="Cari Nama Tryout"
                v-model="events_id"
                :value="id"
                :reduce="(nama) => nama.id"
                :options="listEventTryout"
                label="nama"
              >
              </v-select>
            </b-col>
          <b-col md="6" xl="2" class="mb-0">
            <label for="textarea-default">.</label>
            <b-button
              variant="success"
              style="width: 100%"
              type="submit"
            >
              <span class="align-middle"
                ><b style="color: white"> Cetak</b></span
              >
            </b-button>
          </b-col>
          </b-row>
        </b-form>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->

            <span
              v-if="props.column.field === 'register'"
              class="btn-icon text-center"
            >
              <b-button
                variant="gradient-primary"
                class="btn-icon"
                @click="
                  $router.push({
                    name: 'list_pembahasan_hasil_tryout_admin',
                    params: { id: props.row.id },
                  })
                ">
                <feather-icon icon="BookOpenIcon" /> Ulasan
              </b-button>
              &nbsp;
              <b-button
                variant="gradient-primary"
                class="btn-icon"
                @click="
                  $router.push({
                    name: 'list_analisa_hasil_tryout_admin',
                    params: { id: props.row.id },
                  })
                "
              >
                <feather-icon icon="SearchIcon" /> Analisa
              </b-button>
            </span>

            <span
              v-else-if="props.column.field === 'id'"
              class="btn-icon text-center"
            >
            {{ props.index + 1}}
                </span>
            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="btn-icon text-center"
            >
       
                  <b-button
                    variant="gradient-success"
                    class="btn-icon"
                    @click="
                      $router.push({
                        name: 'edit_plan',
                        params: { id: props.row.id },
                      })
                    "
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  &nbsp;
                  <b-button
                    variant="gradient-danger"
                    class="btn-icon"
                    @click="deleteData(props.row.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
               
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '20', '50']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  :align="pagnation_posisition"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-card>
  </section>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BCardHeader,
  BTooltip,
  BCardTitle,
  BNav,
  BNavItem,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table/src";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    VueGoodTable,
    flatPickr,
    BCard,
    ToastificationContent,
    BAvatar,
    Swal,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCardHeader,
    BNav,
    BNavItem,
    BTooltip,
    BCardTitle,
    BCol,
    vSelect,
  },
  data() {
    return {
      selected: "right",
      pagnation_posisition: "right",
      pageLength: 10,
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        //   tdClass: 'text-center',
        //   thClass: 'text-center',
        // },
        {
          label: "Nama",
          field: "nama_user",
        },
        {
          label: "TryOut",
          field: "nama_soal",
        },
        {
          label: "Hasil",
          field: "score",
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        // {
        //   label: "Durasi",
        //   field: "durasi",
        //   tdClass: 'text-center',
        //   thClass: 'text-center',
        // },
        // {
        //   label: "Soal",
        //   field: "jumlah_soal",
        //   tdClass: 'text-center',
        //   thClass: 'text-center',
        // },
        {
          label: "Status",
          field: "status",
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        // {
        //   label: "Evaluasi",
        //   field: "register",
        //   tdClass: 'text-center',
        //   thClass: 'text-center',
        // },
      ],
      rows: [],
      searchTerm: "",
      id_session: localStorage.getItem("Uid"),
      tanggal_awal:"",
      tanggal_akhir:"",
      listEvent: [],
      events_id:"",
      listEventTryout:[]
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
  },
  async mounted() {
    this.checkAuth();
    await this.getData();
    this.getEventTryout();
  },

  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    async getData() {
      await this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL 
        + `plans/view_user_admin`)
        .then((res) => {
          this.rows = res.data.data;
        });
    },
    getEventTryout() {
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "plans/view")
        .then((res) => {
          this.listEventTryout = res.data.data;
        });
    },
    list_report_event() {
      const getEvent = this.events_id
      window.open(process.env.VUE_APP_BACKEND_URL + `report/report_event?events_id=${getEvent}`, '_blank')
    },
  
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>